<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-else>
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="mb-8" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4">{{ 'companyProfile'|localize }}</h1>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-container class="px-4">
          <v-form ref="companyProfile" lazy-validation>
            <v-row class="mb-2" align="center" justify="space-between">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'company'|localize }}</h6>
                <v-text-field
                    disabled
                    v-model.trim="company_name"
                    outlined
                    dense
                    :placeholder="'addYourCompanyName'|localize"
                    :rules="[validationRules.required_long]"
                />
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'phoneNumber'|localize }}</h6>
                <v-text-field
                    v-model.trim="phone"
                    outlined
                    dense
                    :placeholder="'phoneNumber'|localize"
                    :rules="[validationRules.required_long]"
                />
              </v-col>
            </v-row>
            <v-row class="mb-2" align="center" justify="space-between">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'contactPersonEmails'|localize }} </h6>
                <v-text-field
                    v-model="emails"
                    outlined
                    dense
                    :placeholder="'addEmails'|localize"
                    :hint="'addEmailsHint'|localize"
                    persistent-hint
                />
              </v-col>
            </v-row>
            <v-row align="center" justify="space-between">
              <v-col cols="auto" class="ml-auto">
                <v-btn
                    rounded
                    depressed
                    color="primary"
                    :loading="saveLoading"
                    :disabled="saveLoading"
                    @click="updateCompanyProfile"
                > {{ 'save'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </template>
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import localize from '@/filters/localize';
  import validationRules from '../helpers/validationRules';
  import CompanyRelevancy from '@/components/CompanyRelevancy.vue';

  export default {
    name: "CompanyProfile",
    components: {CompanyRelevancy},

    data() {
      return {
        validationRules: validationRules,
        company_name: '',
        phone: '',
        emails: '',
        getLoading: false,
        saveLoading: false
      };
    },
    async mounted() {
      try {
        this.getLoading = true;
        let companyProfileData = await api.getCompanyProfile(this.userData.company.id);
        this.setCompanyProfile(companyProfileData.data)
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
      }
    },
    computed: {
      breadcrumbsItems() {
        return [
          {
            text: localize('myRequests'),
            disabled: false,
            to: {name: 'company-requests'}
          },
          {
            text: localize('companyProfile'),
            disabled: true,
          },
        ]
      },
      userData() {
        return this.$store.getters.userData;
      },
      companyProfileData() {
        let companyProfileData = {};
        companyProfileData.id = this.userData.company.id;
        companyProfileData.name = this.company_name;
        companyProfileData.phone = this.phone;
        if(this.emails) companyProfileData.email = this.emails.split(',').map(s => s.trim());
        return companyProfileData;
      }
    },
    methods: {
      setCompanyProfile(companyProfileData) {
        this.company_name = companyProfileData.name;
        this.phone = companyProfileData.phone;
        this.emails = companyProfileData.emails;
      },
      async updateCompanyProfile() {
        if(this.$refs.companyProfile.validate()) {
          try {
            this.saveLoading = true;
            await api.updateCompanyProfile(this.companyProfileData);
            methods.showGlobalSnackbar('success', localize('done'));
            this.saveLoading = false;
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
    }
  }
</script>
